import Gumshoe from 'gumshoejs';

const menuMobileElement = function() {
	const headerHeight = document.querySelector('.header').clientHeight;

	let menuMobile = new Gumshoe('.menu-mobile__link', {
		navClass: 'menu-mobile__element_active',
		offset: headerHeight
	});

	const menuMobileElements = document.querySelectorAll('.menu-mobile__element');

	const body = document.querySelector('body');
	const header = document.querySelector('.header');
	const menuFormButton = document.querySelector('.menu-form__button');
	const menuPopup = document.querySelector('.menu-mobile-wrapper');

	if (menuMobileElements) {
		for (let i = 0; i < menuMobileElements.length; i++) {
			menuMobileElements[i].addEventListener('click', function() {
				body.classList.remove('no-scroll');
				header.classList.remove('header_mobile-menu');
				menuFormButton.classList.remove('menu-form__button_active');
				menuPopup.classList.remove('menu-mobile-wrapper_visible');
			})
		}
	}
}

export {menuMobileElement};
