import {menuElement} from './menu-element';
import {menuMobileElement} from './menu-mobile-element';
import {menuMobile} from './menu-mobile';
import {headerStick} from './header-stick';
import {progressBar} from './progress-bar';

const header = function() {
	menuElement();
	menuMobileElement();
	menuMobile();
	headerStick();
	progressBar();
}

export {header};
