import { tween, styler, chain, easing, stagger } from 'popmotion';
import Vivus from 'vivus';

const mainServices = function() {

	const mainServicesBlock = document.querySelectorAll('.main-services');
	const animateBlocks = function(elements) {
		return elements.map(element => {
			let blockStyler = styler(element);
			return tween({
				from: { y: 60, opacity: 0 },
				to: { y: 0, opacity: 1 },
				ease: easing.easeInOut,
				duration: 300
			}).pipe(blockStyler.set);
		})
	};

	let animateLines;
	if (window.innerWidth > 992) {
		animateLines = function(elements) {
			return elements.map(element => {
				let blockStyler = styler(element);
				return tween({
					from: { rotate: 8, height: 0 },
					to: { rotate: 8, height: 400 },
					ease: easing.easeInOut,
					duration: 300
				}).pipe(blockStyler.set);
			})
		};
	} else {
		animateLines = function(elements) {
			return elements.map(element => {
				let blockStyler = styler(element);
				return tween({
					from: { rotate: 87, height: 0 },
					to: { rotate: 87, height: 992 },
					ease: easing.easeInOut,
					duration: 300
				}).pipe(blockStyler.set);
			})
		};
	}

	const animateButtons = function(elements) {
		return elements.map(element => {
			let buttonStyler = styler(element);
			return tween({
				from: { scale: 0 },
				to: { scale: 1 },
				ease: easing.easeInOut,
				duration: 300
			}).pipe(buttonStyler.set);
		})
	};
	

	const vivusOptions = {
		type: 'delayed',
		duration: 200,
		delay: 100,
		start: 'manual'
	};

	if (mainServicesBlock) {
		
		for (let i = 0; i < mainServicesBlock.length; i++) {
			let check = false;
			const options = {
				threshold: [0, 0.25, 0.5, 0.75, 1]
			}
	
			const caption = mainServicesBlock[i].querySelector('.main-services__caption');
			caption.style.opacity = '0';
			const captionStyler = styler(caption);
	
			const title = mainServicesBlock[i].querySelector('.main-services__title');
			title.style.opacity = '0';
			const titleStyler = styler(title);
	
			const subtitle = mainServicesBlock[i].querySelector('.main-services__subtitle');
			subtitle.style.opacity = '0';
			const subtitleStyler = styler(subtitle);
	
			const blocks = Array.from(mainServicesBlock[i].querySelectorAll('.main-services__block'));
			for (let i = 0; i < blocks.length; i++) {
				blocks[i].style.opacity = '0';
			}

			const lines = Array.from(mainServicesBlock[i].querySelectorAll('.main-services__line'));
			for (let i = 0; i < lines.length; i++) {
				lines[i].style.height = '0';
			}
	
			const buttons = Array.from(mainServicesBlock[i].querySelectorAll('.button-tomato'));
			for (let i = 0; i < lines.length; i++) {
				buttons[i].style.transform = 'scale(0)';
			}

			if (mainServicesBlock[i].classList.contains('main-services-ciaas')) {
				var svgServicesCiaasTimer = new Vivus('services-ciaas-timer', vivusOptions);
				var svgServicesCiaasMagnifier = new Vivus('services-ciaas-magnifier', vivusOptions);
				var svgServicesCiaasData = new Vivus('services-ciaas-data', vivusOptions);
			}
			if (mainServicesBlock[i].classList.contains('main-services-research')) {
				var svgServicesResearchCompetitors = new Vivus('services-research-competitors', vivusOptions);
				var svgServicesResearchData = new Vivus('services-research-data', vivusOptions);
				var svgServicesResearchAirplane = new Vivus('services-research-airplane', vivusOptions);
			}

			const observer = new IntersectionObserver(entry => {
				if (entry[0].intersectionRatio > 0.25) {
					if(!check) {
						check = true;
						chain(
							tween({
								from: { y: 60, opacity: 0 },
								to: { y: 0, opacity: 1 },
								ease: easing.easeInOut,
								duration: 300
							}).pipe(captionStyler.set),
							tween({
								from: { y: 60, opacity: 0 },
								to: { y: 0, opacity: 1 },
								ease: easing.easeInOut,
								duration: 300
							}).pipe(titleStyler.set),
							tween({
								from: { y: 60, opacity: 0 },
								to: { y: 0, opacity: 1 },
								ease: easing.easeInOut,
								duration: 300
							}).pipe(subtitleStyler.set),
							stagger(animateBlocks(blocks), 100).pipe(function() {
								if (mainServicesBlock[i].classList.contains('main-services-ciaas')) {
									svgServicesCiaasTimer.reset().play();
									svgServicesCiaasMagnifier.reset().play();
									svgServicesCiaasData.reset().play();
								}
								if (mainServicesBlock[i].classList.contains('main-services-research')) {
									svgServicesResearchCompetitors.reset().play();
									svgServicesResearchData.reset().play();
									svgServicesResearchAirplane.reset().play();
								}
							}),
							stagger(animateLines(lines), 0),
							stagger(animateButtons(buttons), 0),
						).start();
					}
				}
			}, options);
			observer.observe(mainServicesBlock[i]);
		}
	};
}

export {mainServices};