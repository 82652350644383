import Gumshoe from 'gumshoejs';

const menuElement = function() {
	const headerHeight = document.querySelector('.header').clientHeight;

	let menuMain = new Gumshoe('.menu-main__link', {
		navClass: 'menu-main__element_active',
		offset: headerHeight
	});
}

export {menuElement};
