const form = () => {
	const inputs = Array.from(document.querySelectorAll(".form__input"));

	inputs.map(input => {
		const wrapper = input.closest(".form__element");

		input.addEventListener("blur", () => {
			if (!input.value) {
				wrapper.classList.remove("form__element_error");
			}

			if (input.dataset.required) {
				if (input.value === "") {
					wrapper.classList.add("form__element_error");
				}
			}
		})

		input.addEventListener("valid", () => {
			input.parentNode.classList.remove("form__element_error");
		})

		input.addEventListener("input", e => {
			if (input.dataset.required) {
				input.value = e.target.value;
				if (input.value === "") {
					wrapper.classList.add("form__element_error");
				} else {
					wrapper.classList.remove("form__element_error");
				}
			}
		})
	});

	const forms = Array.from(document.querySelectorAll(".form"));
	forms.map(form => {
		form.addEventListener("submit", e => {
			e.preventDefault();
			e.stopPropagation();

			const formData = new FormData(form);
			// console.log(formData);
			const fetchOptions = {
				method: "POST",
				// С headers данные почему то не доходят
				/* headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				}, */
				credentials: "include",
				redirect: "follow",
				body: formData
			}
			let inputsCheck = false, checkboxsCheck = false;

			const inputs = form.querySelectorAll(".form__input");
			for (let i = 0; i < inputs.length; i++) {
				const formElement = inputs[i].closest(".form__element");
				if (inputs[i].value == '') {
					formElement.classList.add("form__element_error");
					inputsCheck = true;
				} else {
					formElement.classList.remove("form__element_error");
					inputsCheck = false;
				}
			}

			const checkboxs = form.querySelectorAll(".form__checkbox");
			for (let i = 0; i < checkboxs.length; i++) {
				const formElement = checkboxs[i].closest(".form__element");
				if (checkboxs[i].checked == false) {
					formElement.classList.add("form__element_error");
					checkboxsCheck = true;
				} else {
					formElement.classList.remove("form__element_error");
					checkboxsCheck = false;
				}
			}

			// console.log(inputsCheck);
			// console.log(checkboxsCheck);
			const responsePromise = fetch(form.action, fetchOptions);
			if (inputsCheck == false && checkboxsCheck == false) {
				responsePromise.then(function(response) { /* По умолчанию в response всегда успех, регулируется это в .php */
					if (!response.ok) {
						console.log("Ошибка HTTP: " + response.status);
						throw response;
					}
					return response.text();
				}).then(function(data) {
					// console.log(data);
					form.querySelector(".form__message").classList.add("form__message_visible");
					form.querySelector(".form__button").classList.add("form__button_hidden");
				});
			}
		});
	});
}

export {form};