import Vivus from 'vivus';

import {mainSliderObserver} from '../slider'

const opportunities = () => {
	let slide = {};
	const vivusOptions = {
		type: 'delayed',
		duration: 200,
		delay: 100,
		start: 'manual'
	};
	const svgServicesResearchSliderMail = new Vivus('popup-services-research-slider-mail', vivusOptions);

	mainSliderObserver.subscribe(slides => {
		slide = slides.active;
		if (slide.classList.contains('popup-services-slider__slide_opportunities')) {
			svgServicesResearchSliderMail.el.style.visibility = 'visible';
			svgServicesResearchSliderMail.reset().play();
		} else {
			svgServicesResearchSliderMail.reset();
			svgServicesResearchSliderMail.el.style.visibility = 'hidden';
		}
	})
}

export {opportunities};
