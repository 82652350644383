import { tween, styler, chain, easing, stagger } from 'popmotion';

const mainExpertis = function() {

	const mainExpertisBlock = document.querySelector('.main-expertis');
	const animateBlocks = function(elements) {
		return elements.map(element => {
			let blockStyler = styler(element);
			return tween({
				from: { y: 60, opacity: 0 },
				to: { y: 0, opacity: 1 },
				ease: easing.easeInOut,
				duration: 300
			}).pipe(blockStyler.set); // .start()
		})
	};

	let animateLines;
	if (window.innerWidth > 992) {
		animateLines = function(elements) {
			return elements.map(element => {
				let blockStyler = styler(element);
				return tween({
					from: { rotate: 29, height: 0 },
					to: { rotate: 29, height: 199 },
					ease: easing.easeInOut,
					duration: 300
				}).pipe(blockStyler.set); // .start()
			})
		};
	} else {
		animateLines = function(elements) {
			return elements.map(element => {
				let blockStyler = styler(element);
				return tween({
					from: { rotate: 29, height: 0 },
					to: { rotate: 29, height: 124 },
					ease: easing.easeInOut,
					duration: 300
				}).pipe(blockStyler.set); // .start()
			})
		};
	}

	if (mainExpertisBlock) {

		let check = false;
		const options = {
			threshold: [0, 0.25, 0.5, 0.75, 1]
		}

		const title = mainExpertisBlock.querySelector('.main-expertis__title');
		title.style.opacity = '0';
		const titleStyler = styler(title);

		const blocks = Array.from(mainExpertisBlock.querySelectorAll('.main-expertis__block'));
		for (let i = 0; i < blocks.length; i++) {
			blocks[i].style.opacity = '0';
		}

		const lines = Array.from(mainExpertisBlock.querySelectorAll('.main-expertis__line'));
		for (let i = 0; i < lines.length; i++) {
			lines[i].style.height = '0';
		}
		
		const observer = new IntersectionObserver(entry => {
			if (entry[0].intersectionRatio > 0.25) {
				if(!check) {
					check = true;
					chain(
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 300
						}).pipe(titleStyler.set),
						stagger(animateBlocks(blocks), 100),
						stagger(animateLines(lines), 0)
					).start(); // .start()
				}
			}
		}, options);
		observer.observe(mainExpertisBlock);
	};
}

export {mainExpertis};
