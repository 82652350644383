const menuMobile = function() {
	const body = document.querySelector('body');
	const header = document.querySelector('.header');
	const menuFormButton = document.querySelector('.menu-form__button');
	const menuPopup = document.querySelector('.menu-mobile-wrapper');

	if (menuFormButton) {
		menuFormButton.addEventListener('click', function() {
			if (!this.classList.contains('menu-form__button_active')) {
				body.classList.add('no-scroll');
				header.classList.add('header_mobile-menu');
				this.classList.add('menu-form__button_active');
				menuPopup.classList.add('menu-mobile-wrapper_visible');
			} else if (this.classList.contains('menu-form__button_active')) {
				body.classList.remove('no-scroll');
				header.classList.remove('header_mobile-menu');
				this.classList.remove('menu-form__button_active');
				menuPopup.classList.remove('menu-mobile-wrapper_visible');
			}
		})
	}
}

export {menuMobile};
