import tingle from 'tingle.js/src/tingle'

import {popupServicesInformation} from '../animate/popup-services-information';
import {popupServicesAssistance} from '../animate/popup-services-assistance';
import {popupServicesAdvantages} from '../animate/popup-services-advantages';

const servicesCiaas = document.querySelector('.main-services-ciaas');

const popup = function() {
	const popupCiaas = document.querySelector('.popup-services_ciaas');
	const buttonCiaasOpen = document.querySelectorAll('.button-ciaas-open');
	const buttonCiaasClose = document.querySelectorAll('.button-ciaas-close');

	const modalCiaas = new tingle.modal({
		footer: false,
		stickyFooter: false,
		closeMethods: ['escape'],
		closeLabel: 'Close',
		cssClass: ['popup-services-tingle', 'popup-services-tingle_ciaas'],
		beforeOpen: function() {
			const tingleModals = document.querySelectorAll('.tingle-modal');
			for (let i = 0; i < tingleModals.length; i++) {
				tingleModals[i].classList.remove('tingle-modal--visible');
			}
			
			const popupTingleCiaas = document.querySelector('.popup-services-tingle_ciaas');
			popupTingleCiaas.scrollTop = 0;

			popupServicesInformation();
			popupServicesAssistance();
			popupServicesAdvantages();
		}
	})

	modalCiaas.setContent(popupCiaas);

	if (buttonCiaasOpen) {
		for (let i = 0; i < buttonCiaasOpen.length; i++) {
			buttonCiaasOpen[i].addEventListener('click', function() {
				modalCiaas.open();
			});
		}
	}

	if (buttonCiaasClose) {
		for (let i = 0; i < buttonCiaasClose.length; i++) {
			buttonCiaasClose[i].addEventListener('click', function() {
				modalCiaas.close();

				let progress = servicesCiaas.offsetTop;
				window.scrollTo(0, progress);
			});
		}
	}
}

export {popup};
