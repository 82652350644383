import { tween, styler, chain, easing } from 'popmotion';

const mainContacts = function() {

	const mainContactsBlock = document.querySelector('.main-contacts');

	if (mainContactsBlock) {

		let check = false;
		const options = {
			threshold: [0, 0.25, 0.5, 0.75, 1]
		}

		const title = mainContactsBlock.querySelector('.main-contacts__title');
		title.style.opacity = '0';
		const titleStyler = styler(title);

		const paragraph = mainContactsBlock.querySelector('.main-contacts__paragraph');
		paragraph.style.opacity = '0';
		const paragraphStyler = styler(paragraph);

		const form = mainContactsBlock.querySelector('.form');
		form.style.opacity = '0';
		const formStyler = styler(form);
		
		const observer = new IntersectionObserver(entry => {
			if (entry[0].intersectionRatio > 0.25) {
				if(!check) {
					check = true;
					chain(
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 300
						}).pipe(titleStyler.set),
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 300
						}).pipe(paragraphStyler.set),
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 300
						}).pipe(formStyler.set)
					).start();
				}
			}
		}, options);
		observer.observe(mainContactsBlock);
	};
}

export {mainContacts};
