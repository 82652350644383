import { tween, styler, chain, easing } from 'popmotion';

const mainIntelligence = function() {

	const mainIntelligenceBlock = document.querySelector('.main-intelligence');

	if (mainIntelligenceBlock) {

		let check = false;
		const options = {
			threshold: [0, 0.25, 0.5, 0.75, 1]
		}

		const columnLeft = mainIntelligenceBlock.querySelector('.main-intelligence__column_left');
		columnLeft.style.opacity = '0';
		const columnLeftStyler = styler(columnLeft);

		const columnRight = mainIntelligenceBlock.querySelector('.main-intelligence__column_right');
		columnRight.style.opacity = '0';
		const columnRightStyler = styler(columnRight);

		const observer = new IntersectionObserver(entry => {
			if (entry[0].intersectionRatio > 0.25) {
				if(!check) {
					check = true;
					chain(
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 500
						}).pipe(columnLeftStyler.set),
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 500
						}).pipe(columnRightStyler.set)
					).start();
				}
			}
		}, options);
		observer.observe(mainIntelligenceBlock);
	};
}

export {mainIntelligence};
