import { tween, styler, chain, easing } from 'popmotion';

const popupServicesAdvantages = function() {

	const popupServicesAdvantagesBlock = document.querySelectorAll('.popup-services-advantages');

	if (popupServicesAdvantagesBlock) {

		for (let i = 0; i < popupServicesAdvantagesBlock.length; i++) {
			let check = false;
			const options = {
				threshold: [0, 0.25, 0.5, 0.75, 1]
			}
	
			const caption = popupServicesAdvantagesBlock[i].querySelector('.popup-services-advantages__caption');
			caption.style.opacity = '0';
			const captionStyler = styler(caption);
	
			const title = popupServicesAdvantagesBlock[i].querySelector('.popup-services-advantages__title');
			title.style.opacity = '0';
			const titleStyler = styler(title);

			const description = popupServicesAdvantagesBlock[i].querySelector('.popup-services-advantages__description');
			description.style.opacity = '0';
			const descriptionStyler = styler(description);
			
			const observer = new IntersectionObserver(entry => {
				if (entry[0].intersectionRatio > 0.25) {
					if(!check) {
						check = true;
						chain(
							tween({
								from: { y: 60, opacity: 0 },
								to: { y: 0, opacity: 1 },
								ease: easing.easeInOut,
								duration: 300
							}).pipe(captionStyler.set),
							tween({
								from: { y: 60, opacity: 0 },
								to: { y: 0, opacity: 1 },
								ease: easing.easeInOut,
								duration: 300
							}).pipe(titleStyler.set),
							tween({
								from: { y: 60, opacity: 0 },
								to: { y: 0, opacity: 1 },
								ease: easing.easeInOut,
								duration: 300
							}).pipe(descriptionStyler.set)
						).start();
					}
				}
			}, options);
			observer.observe(popupServicesAdvantagesBlock[i]);
		}
	};
}

export {popupServicesAdvantages};