import {mainProvide} from './main-provide';
import {mainIntelligence} from './main-intelligence';
import {mainHero} from './main-hero';
import {mainAbout} from './main-about';
import {mainExpertis} from './main-expertis';
import {mainServices} from './main-services';
import {mainClients} from './main-clients';
import {mainStartups} from './main-startups';
import {mainContacts} from './main-contacts';

// import {popupServicesHero} from './popup-services-hero';
// import {popupServicesInformation} from './popup-services-information';
// import {popupServicesAssistance} from './popup-services-assistance';
// import {popupServicesAdvantages} from './popup-services-advantages';

// import { chain } from 'popmotion';

const animate = function() {
	mainProvide();
	mainIntelligence();
	mainHero();
	mainAbout();
	mainExpertis();
	mainServices();
	mainClients();
	mainStartups();
	mainContacts();

	// popupServicesHero();
	// popupServicesInformation();
	// popupServicesAssistance();
	// popupServicesAdvantages();

	// chain(mainAbout(), mainExpertis()).start();
}

export {animate};
