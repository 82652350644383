import '@babel/polyfill'; // Полифил для IE 11
import 'intersection-observer'; // Полифил для IE 11

import 'focus-visible'; // Полифил для :focus-visible
import objectFitImages from 'object-fit-images'; // Полифил для object-fit

import {globalScripts} from './global';
import {animate} from './animate';
import {header} from './header';
import {mainHero} from './main-hero';
import {parallax} from './parallax';
import {popupCiaas} from './popup-ciaas';
import {popupResearch} from './popup-research';
import {popupServices} from './popup-services';
import {popupForm} from './popup-form';

document.addEventListener('DOMContentLoaded', function() {
	objectFitImages(); // Полифил для object-fit

	globalScripts();
	animate();
	header();
	mainHero();
	parallax();
	popupCiaas();
	popupResearch();
	popupServices();
	popupForm();
})
