import { tween, styler, chain, easing } from 'popmotion';

const mainAbout = function() {

	const mainAboutBlock = document.querySelector('.main-about');
	// const captionProps = {
	// 	from: { y: 60, opacity: 0 },
	// 	to: { y: 0, opacity: 1 },
	// 	ease: easing.easeInOut,
	// 	duration: 300
	// }
		
	// const titleProps = {
	// 	from: { y: 60, opacity: 0 },
	// 	to: { y: 0, opacity: 1 },
	// 	ease: easing.easeInOut,
	// 	duration: 300
	// }

	if (mainAboutBlock) {

		let check = false;
		const options = {
			threshold: [0, 0.25, 0.5, 0.75, 1]
		}

		const caption = mainAboutBlock.querySelector('.main-about__caption');
		caption.style.opacity = '0';
		const captionStyler = styler(caption);

		const title = mainAboutBlock.querySelector('.main-about__title');
		title.style.opacity = '0';
		const titleStyler = styler(title);

		// tween(captionProps).start(captionStyler.set);

		// tween(titleProps).start(titleStyler.set);

		const observer = new IntersectionObserver(entry => {
			if (entry[0].intersectionRatio > 0.25) {
				if(!check) {
					check = true;
					chain(
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 500
						}).pipe(captionStyler.set),
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 500
						}).pipe(titleStyler.set)
					).start(); // .start()
				}
			}
		}, options);
		observer.observe(mainAboutBlock);
	};

	// v 7.8.2
	// const captionCss = css(caption);

	// const titleCss = css(title);

	// chain([
	// 	tween({
	// 		from: 60,
	// 		to: 0,
	// 		ease: easing.easeInOut,
	// 		onUpdate: v => {
	// 			captionCss.set('y', v);
	// 		}
	// 	}),
	// 	tween({
	// 		from: 60,
	// 		to: 0,
	// 		ease: easing.easeInOut,
	// 		onUpdate: v => {
	// 			titleCss.set('y', v);
	// 		}
	// 	})
	// ]).start();
}

export {mainAbout};
