const startups = function() {
	window.addEventListener("scroll", () => {
		parallaxScroll();
	})
		
	function parallaxScroll() {
		const scrolled = document.querySelector('.main-startups').getBoundingClientRect().top;

		const backgroundParallaxFirst = document.querySelector('.startups-background-parallax-first');
		const backgroundParallaxSecond = document.querySelector('.startups-background-parallax-second');
		const backgroundParallaxThird = document.querySelector('.startups-background-parallax-third');
		const backgroundParallaxFourth = document.querySelector('.startups-background-parallax-fourth');
		const backgroundParallaxFifth = document.querySelector('.startups-background-parallax-fifth');
		const backgroundParallaxSixth = document.querySelector('.startups-background-parallax-sixth');
		
		if (window.innerWidth > 992) {
			backgroundParallaxFirst.style.transform = 'translateY(' + (-299 + (scrolled * 0.25)) + 'px)';
			backgroundParallaxSecond.style.transform = 'translateY(' + (162 + scrolled * 0.75) + 'px)';
			backgroundParallaxThird.style.transform = 'translateY(' + (435 + (scrolled * 0.5)) + 'px)';
			backgroundParallaxFourth.style.transform = 'translateY(' + (743 + scrolled * 0.5) + 'px)';
			backgroundParallaxFifth.style.transform = 'translateY(' + (810 + (scrolled * 0.75)) + 'px)';
			backgroundParallaxSixth.style.transform = 'translateY(' + (1115 + scrolled * 0.75) + 'px)';
		} else {
			backgroundParallaxFirst.style.transform = 'translateY(' + (-104 + (scrolled * 0.25)) + 'px)';
			backgroundParallaxSecond.style.transform = 'translateY(' + (136 + scrolled * 0.75) + 'px)';
			backgroundParallaxThird.style.transform = 'translateY(' + (480 + (scrolled * 0.25)) + 'px)';
			backgroundParallaxFourth.style.transform = 'translateY(' + (637 + scrolled * 0.25) + 'px)';
		}
	}
}

export {startups};
