import Vivus from 'vivus';

import {mainSliderObserver} from '../slider'

const analysis = () => {
	let slide = {};
	const vivusOptions = {
		type: 'delayed',
		duration: 200,
		delay: 100,
		start: 'manual'
	};
	const svgServicesResearchSliderAirplaneLong = new Vivus('popup-services-research-slider-airplane-long', vivusOptions);

	mainSliderObserver.subscribe(slides => {
		slide = slides.active;
		if (slide.classList.contains('popup-services-slider__slide_analysis')) {
			svgServicesResearchSliderAirplaneLong.el.style.visibility = 'visible';
			svgServicesResearchSliderAirplaneLong.reset().play();
		} else {
			svgServicesResearchSliderAirplaneLong.reset();
			svgServicesResearchSliderAirplaneLong.el.style.visibility = 'hidden';
		}
	})
}

export {analysis};
