import Glide, { Controls, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'
import {EventObserver} from '../helper/eventObserver';

import {analysis} from './slides/analysis';
import {market} from './slides/market';
import {competitor} from './slides/competitor';
import {startup} from './slides/startup';
import {opportunities} from './slides/opportunities';
import {research} from './slides/research';

export const mainSliderObserver = new EventObserver();

let sliderResearchInit;

const sliderResearch = function() {
	const glide = document.querySelector('.popup-services-slider_research');
	
	if (glide) {
		analysis();
		market();
		competitor();
		startup();
		opportunities();
		research();

		sliderResearchInit = new Glide('.popup-services-slider_research', {
			type: 'slider',
			startAt: 0,
			perView: 1,
			gap: 40,
			// autoplay: 5000
		});
	
		sliderResearchInit.mount({
			Controls,
			Swipe,
			Autoplay
		});

		sliderResearchInit.on('mount.after', slide => {
			mainSliderObserver.broadcast({
				active: sliderResearchInit._c.Html.slides[0]
			})
		});
		sliderResearchInit.on(['run.after'], () => {
			mainSliderObserver.broadcast({
				active: sliderResearchInit._c.Html.slides[sliderResearchInit.index]
			})
		});

		const number = glide.querySelector('.popup-services-slider__number');
		const quantitySlides = glide.querySelectorAll('.popup-services-slider__slide').length;
		let currentSlide = sliderResearchInit.index + 1;
		number.innerHTML = currentSlide + '/' + quantitySlides;
		
		sliderResearchInit.on('move', function() {
			currentSlide = sliderResearchInit.index + 1;
			number.innerHTML = currentSlide + '/' + quantitySlides;
		});
	}
}

export {sliderResearch, sliderResearchInit};
