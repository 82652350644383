import Glide, { Controls, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'
import {EventObserver} from '../helper/eventObserver';

export const mainSliderObserver = new EventObserver();

let sliderHeroInit;

const sliderHero = function() {
	const glide = document.querySelector('.main-hero-slider');

	if (glide) {

		sliderHeroInit = new Glide('.main-hero-slider', {
			type: 'slider',
			startAt: 0,
			perView: 1,
			gap: 40,
			autoplay: 3000,
			hoverpause: false
		});
	
		sliderHeroInit.mount({
			Controls,
			Swipe,
			Autoplay
		});
		
		sliderHeroInit.on('mount.after', slide => {
			mainSliderObserver.broadcast({
				active: sliderHeroInit._c.Html.slides[0]
			})
		});
		sliderHeroInit.on(['run.after'], () => {
			mainSliderObserver.broadcast({
				active: sliderHeroInit._c.Html.slides[sliderHeroInit.index]
			})
		});
	}
}

export {sliderHero, sliderHeroInit};
