import tingle from 'tingle.js/src/tingle'

import {popupServicesInformation} from '../animate/popup-services-information';
import {popupServicesAssistance} from '../animate/popup-services-assistance';
import {popupServicesAdvantages} from '../animate/popup-services-advantages';

const servicesResearch = document.querySelector('.main-services-research');

const popup = function() {
	const popupResearch = document.querySelector('.popup-services_research');
	const buttonResearchOpen = document.querySelectorAll('.button-research-open');
	const buttonResearchClose = document.querySelectorAll('.button-research-close');

	const modalResearch = new tingle.modal({
		footer: false,
		stickyFooter: false,
		closeMethods: ['escape'],
		closeLabel: 'Close',
		cssClass: ['popup-services-tingle', 'popup-services-tingle_research'],
		beforeOpen: function() {
			const tingleModals = document.querySelectorAll('.tingle-modal');
			for (let i = 0; i < tingleModals.length; i++) {
				tingleModals[i].classList.remove('tingle-modal--visible');
			}
			
			const popupTingleResearch = document.querySelector('.popup-services-tingle_research');
			popupTingleResearch.scrollTop = 0;

			popupServicesInformation();
			popupServicesAssistance();
			popupServicesAdvantages();
		}
	})

	modalResearch.setContent(popupResearch);

	if (buttonResearchOpen) {
		for (let i = 0; i < buttonResearchOpen.length; i++) {
			buttonResearchOpen[i].addEventListener('click', function() {
				modalResearch.open();
			});
		}
	}

	if (buttonResearchClose) {
		for (let i = 0; i < buttonResearchClose.length; i++) {
			buttonResearchClose[i].addEventListener('click', function() {
				modalResearch.close();

				let progress = servicesResearch.offsetTop;
				window.scrollTo(0, progress);
			});
		}
	}
}

export {popup};
