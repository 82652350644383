import { tween, styler, chain, easing, stagger } from 'popmotion';

const mainClients = function() {

	const mainClientsBlock = document.querySelector('.main-clients');
	const animateGroup = function(elements) {
		return elements.map(element => {
			let blockStyler = styler(element);
			return tween({
				from: { y: 60, opacity: 0 },
				to: { y: 0, opacity: 1 },
				ease: easing.easeInOut,
				duration: 200
			}).pipe(blockStyler.set); // .start()
		})
	};

	if (mainClientsBlock) {

		let check = false;
		const options = {
			threshold: [0, 0.25, 0.5, 0.75, 1]
		}

		const caption = mainClientsBlock.querySelector('.main-clients__caption');
		caption.style.opacity = '0';
		const captionStyler = styler(caption);

		// const title = mainClientsBlock.querySelector('.main-clients__title');
		// title.style.opacity = '0';
		// const titleStyler = styler(title);

		const blocks = mainClientsBlock.querySelector('.main-clients__blocks');
		const block = Array.from(blocks.querySelectorAll('.clients__picture'));
		for (let i = 0; i < block.length; i++) {
			block[i].style.opacity = '0';
		}
		
		const observer = new IntersectionObserver(entry => {
			if (entry[0].intersectionRatio > 0.25) {
				if(!check) {
					check = true;
					chain(
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 300
						}).pipe(captionStyler.set),
						// tween({
						// 	from: { y: 60, opacity: 0 },
						// 	to: { y: 0, opacity: 1 },
						// 	ease: easing.easeInOut,
						// 	duration: 300
						// }).pipe(titleStyler.set),
						stagger(animateGroup(block), 100)
					).start();
				}
			}
		}, options);
		observer.observe(mainClientsBlock);
	};
}

export {mainClients};
