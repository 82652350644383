import Vivus from 'vivus';

import {mainSliderObserver} from '../slider'

const research = () => {
	let slide = {};
	const vivusOptions = {
		type: 'delayed',
		duration: 200,
		delay: 100,
		start: 'manual'
	};
	const svgServicesResearchSliderAim = new Vivus('popup-services-research-slider-aim', vivusOptions);

	mainSliderObserver.subscribe(slides => {
		slide = slides.active;
		if (slide.classList.contains('popup-services-slider__slide_research')) {
			svgServicesResearchSliderAim.el.style.visibility = 'visible';
			svgServicesResearchSliderAim.reset().play();
		} else {
			svgServicesResearchSliderAim.reset();
			svgServicesResearchSliderAim.el.style.visibility = 'hidden';
		}
	})
}

export {research};
