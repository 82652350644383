import Headroom from 'headroom.js'

const popupHeaderStick = function() {
	const popupTingle = document.querySelectorAll('.popup-services-tingle');

	if (popupTingle) {
		for (let i = 0; i < popupTingle.length; i++) {
			const header = popupTingle[i].querySelector('.popup-services-header');

			if (header) {
				const headroom = new Headroom(header, {
					offset: 40,
					scroller: popupTingle[i]
				});
				headroom.init();
			}
		}
	}
}

export {popupHeaderStick};
