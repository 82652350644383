import { tween, styler, chain, easing } from 'popmotion';

import {sliderCiaasInit} from '../popup-ciaas/slider';
import {sliderResearchInit} from '../popup-research/slider';

const popupServicesAssistance = function() {

	const popupServicesAssistanceBlock = document.querySelectorAll('.popup-services-assistance');

	const sliderCiaas = document.querySelector('.popup-services-slider_ciaas');
	const sliderCiaasButton = sliderCiaas.querySelectorAll('.popup-services-slider__button');

	if (popupServicesAssistanceBlock) {

		for (let i = 0; i < popupServicesAssistanceBlock.length; i++) {
			let check = false;
			const options = {
				threshold: [0, 0.25, 0.5, 0.75, 1]
			}
	
			const caption = popupServicesAssistanceBlock[i].querySelector('.popup-services-assistance__caption');
			caption.style.opacity = '0';
			const captionStyler = styler(caption);
	
			const slider = popupServicesAssistanceBlock[i].querySelector('.popup-services-slider');
			slider.style.opacity = '0';
			const sliderStyler = styler(slider);
			
			const observer = new IntersectionObserver(entry => {
				if (entry[0].intersectionRatio > 0.25) {
					if(!check) {
						check = true;
						chain(
							tween({
								from: { y: 60, opacity: 0 },
								to: { y: 0, opacity: 1 },
								ease: easing.easeInOut,
								duration: 300
							}).pipe(captionStyler.set),
							tween({
								from: { y: 60, opacity: 0 },
								to: { y: 0, opacity: 1 },
								ease: easing.easeInOut,
								duration: 300
							}).pipe(sliderStyler.set)
						).start();

						for (let i = 0; i < sliderCiaasButton.length; i++) {
							sliderCiaasButton[i].classList.remove('glide__bullet--active');
						}
						sliderCiaasInit.go('=0');

						sliderResearchInit.go('=0');
					}
				}
			}, options);
			observer.observe(popupServicesAssistanceBlock[i]);
		}
	};
}

export {popupServicesAssistance};