import { tween, styler, chain, easing } from 'popmotion';

import {sliderHeroInit} from '../main-hero/slider';

const mainHero = function() {
	
	const mainHeroBlock = document.querySelector('.main-hero');

	if (mainHeroBlock) {

		let check = false;
		const options = {
			threshold: [0, 0.25, 0.5, 0.75, 1]
		}

		const logo = mainHeroBlock.querySelector('.main-hero__logo-wrapper');
		logo.style.opacity = '0';
		const logoStyler = styler(logo);

		const text = mainHeroBlock.querySelector('.main-hero__text');
		text.style.opacity = '0';
		const textStyler = styler(text);

		const observer = new IntersectionObserver(entry => {
			if (entry[0].intersectionRatio > 0.25) {
				if(!check) {
					check = true;
					chain(
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 500
						}).pipe(logoStyler.set),
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 500
						}).pipe(textStyler.set)
					).start();
				}

				sliderHeroInit.go('=0');
			}
		}, options);
		observer.observe(mainHeroBlock);
	};
}

export {mainHero};
