import {popupHeaderStick} from './popup-header-stick';
import {popupDescriptionDesktop} from './popup-description-desktop';
import {popupDescriptionMobile} from './popup-description-mobile';

const popupServices = function() {
	popupHeaderStick();
	popupDescriptionDesktop();
	popupDescriptionMobile();
}

export {popupServices};
