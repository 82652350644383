const popupDescriptionMobile = function() {
	const description = document.querySelectorAll('.popup-services-advantages__description.mobile');

	if (description) {
		for (let i = 0; i < description.length; i++) {
			const items = description[i].querySelectorAll('.popup-services-advantages__item');

			for (let j = 0; j < items.length; j++) {
				const term = items[j].querySelector('.popup-services-advantages__term-wrapper');
				const detail = items[j].querySelector('.popup-services-advantages__detail');

				term.addEventListener('click', function() {
					if (!this.classList.contains('popup-services-advantages__term-wrapper_active')) {
						this.classList.add('popup-services-advantages__term-wrapper_active');
						detail.classList.add('popup-services-advantages__detail_visible');
					} else if (this.classList.contains('popup-services-advantages__term-wrapper_active')) {
						this.classList.remove('popup-services-advantages__term-wrapper_active');
						detail.classList.remove('popup-services-advantages__detail_visible');
					}
				});
			}
		}
	}
}

export {popupDescriptionMobile};