import { tween, styler, chain, easing } from 'popmotion';

const mainProvide = function() {

	const mainProvideBlock = document.querySelector('.main-provide');

	if (mainProvideBlock) {

		let check = false;
		const options = {
			threshold: [0, 0.25, 0.5, 0.75, 1]
		}

		const title = mainProvideBlock.querySelector('.main-provide__title');
		title.style.opacity = '0';
		const titleStyler = styler(title);

		const observer = new IntersectionObserver(entry => {
			if (entry[0].intersectionRatio > 0.25) {
				if(!check) {
					check = true;
					chain(
						tween({
							from: { y: 60, opacity: 0 },
							to: { y: 0, opacity: 1 },
							ease: easing.easeInOut,
							duration: 500
						}).pipe(titleStyler.set)
					).start();
				}
			}
		}, options);
		observer.observe(mainProvideBlock);
	};
}

export {mainProvide};
