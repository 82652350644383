import Vivus from 'vivus';

import {mainSliderObserver} from '../slider'

const competitor = () => {
	let slide = {};
	const vivusOptions = {
		type: 'delayed',
		duration: 200,
		delay: 100,
		start: 'manual'
	};
	const svgServicesResearchSliderDiagram = new Vivus('popup-services-research-slider-diagram', vivusOptions);

	mainSliderObserver.subscribe(slides => {
		slide = slides.active;
		if (slide.classList.contains('popup-services-slider__slide_competitor')) {
			svgServicesResearchSliderDiagram.el.style.visibility = 'visible';
			svgServicesResearchSliderDiagram.reset().play();
		} else {
			svgServicesResearchSliderDiagram.reset();
			svgServicesResearchSliderDiagram.el.style.visibility = 'hidden';
		}
	})
}

export {competitor};
