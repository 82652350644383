import Vivus from 'vivus';

import {mainSliderObserver} from '../slider'

const business = () => {
	let slide = {};
	const vivusOptions = {
		type: 'delayed',
		duration: 200,
		delay: 100,
		start: 'manual'
	};
	const svgServicesCiaasSliderMagnifierAngle = new Vivus('popup-services-ciaas-slider-magnifier-angle', vivusOptions);

	mainSliderObserver.subscribe(slides => {
		slide = slides.active;
		if (slide.classList.contains('popup-services-slider__slide_business')) {
			svgServicesCiaasSliderMagnifierAngle.el.style.visibility = 'visible';
			svgServicesCiaasSliderMagnifierAngle.reset().play();
		} else {
			svgServicesCiaasSliderMagnifierAngle.reset();
			svgServicesCiaasSliderMagnifierAngle.el.style.visibility = 'hidden';
		}
	})
}

export {business};
