import tingle from 'tingle.js/src/tingle'

const popup = function() {
	const popupForm = document.querySelector('.popup-form');
	const buttonFormOpen = document.querySelectorAll('.button-form-open');
	const buttonFormClose = document.querySelectorAll('.button-form-close');

	const modalForm = new tingle.modal({
		footer: false,
		stickyFooter: false,
		closeMethods: ['escape'],
		closeLabel: 'Close',
		cssClass: ['popup-form-tingle'],
		beforeOpen: function() {
			const tingleModals = document.querySelectorAll('.tingle-modal');
			for (let i = 0; i < tingleModals.length; i++) {
				tingleModals[i].classList.remove('tingle-modal--visible');
			}
		}
	})

	modalForm.setContent(popupForm);

	if (buttonFormOpen) {
		for (let i = 0; i < buttonFormOpen.length; i++) {
			buttonFormOpen[i].addEventListener('click', function() {
				modalForm.open();
			});
		}
	}

	if (buttonFormClose) {
		for (let i = 0; i < buttonFormClose.length; i++) {
			buttonFormClose[i].addEventListener('click', function() {
				modalForm.close();
			});
		}
	}
}

export {popup};
