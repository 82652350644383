const popupDescriptionDesktop = function() {
	const description = document.querySelectorAll('.popup-services-advantages__description.desktop');

	if (description) {
		for (let i = 0; i < description.length; i++) {
			const term = description[i].querySelectorAll('.popup-services-advantages__term');
			const detail = description[i].querySelectorAll('.popup-services-advantages__detail');

			let n = 0;

			function showPage(n) {
				for (let i = 0; i < detail.length; i++) {
					detail[i].classList.add('popup-services-advantages__detail_invisible');
				}
				detail[n].classList.remove('popup-services-advantages__detail_invisible');

				for (let i = 0; i < term.length; i++) {
					term[i].classList.remove('popup-services-advantages__term_active');
				}
				term[n].classList.add('popup-services-advantages__term_active');
			};

			showPage(0);

			for (let i = 0; i < term.length; i++) {
				term[i].setAttribute('data-page', n);
				n++;
			}

			for (let i = 0; i < term.length; i++) {
				term[i].addEventListener('mouseover', function() {
					showPage(parseInt(this.getAttribute('data-page')));
				});
			}
		}
	}
}

export {popupDescriptionDesktop};
