const progressBar = function() {
	const pageWrapper = document.querySelector('.page-wrapper')
	const headerProgressCircle = document.querySelector('.header-progress__circle');
	let pageHeight, adjustedHeight, progress;

	const radius = headerProgressCircle.getAttribute('r');
	let dashArray, dashOffset;

	if (headerProgressCircle) {
		window.addEventListener("scroll", () => {
			pageHeight = window.innerHeight;
			adjustedHeight = pageWrapper.clientHeight - pageHeight;
			progress = (window.pageYOffset / adjustedHeight) * 100;
	
			dashArray = 2 * Math.PI * radius;
			dashOffset = Math.round(dashArray / 100 * (100 - progress));
	
			/* headerProgressCircle.setAttributeNS(null, 'stroke-dasharray', dashArray); */
			headerProgressCircle.setAttributeNS(null, 'stroke-dashoffset', dashOffset);
		})
	}
}

export {progressBar};
