import Vivus from 'vivus';

import {mainSliderObserver} from '../slider'

const market = () => {
	let slide = {};
	const vivusOptions = {
		type: 'delayed',
		duration: 200,
		delay: 100,
		start: 'manual'
	};
	const svgServicesResearchSliderClock = new Vivus('popup-services-research-slider-clock', vivusOptions);

	mainSliderObserver.subscribe(slides => {
		slide = slides.active;
		if (slide.classList.contains('popup-services-slider__slide_market')) {
			svgServicesResearchSliderClock.el.style.visibility = 'visible';
			svgServicesResearchSliderClock.reset().play();
		} else {
			svgServicesResearchSliderClock.reset();
			svgServicesResearchSliderClock.el.style.visibility = 'hidden';
		}
	})
}

export {market};
