import { tween, styler, chain, easing } from 'popmotion';

const popupServicesInformation = function() {

	const popupServicesInformationBlock = document.querySelectorAll('.popup-services-information');

	if (popupServicesInformationBlock) {

		for (let i = 0; i < popupServicesInformationBlock.length; i++) {
			let check = false;
			const options = {
				threshold: [0, 0.25, 0.5, 0.75, 1]
			}
	
			const caption = popupServicesInformationBlock[i].querySelector('.popup-services-information__caption');
			caption.style.opacity = '0';
			const captionStyler = styler(caption);
	
			const columns = popupServicesInformationBlock[i].querySelector('.popup-services__columns');
			columns.style.opacity = '0';
			const columnsStyler = styler(columns);
			
			const observer = new IntersectionObserver(entry => {
				if (entry[0].intersectionRatio > 0.25) {
					if(!check) {
						check = true;
						chain(
							tween({
								from: { y: 60, opacity: 0 },
								to: { y: 0, opacity: 1 },
								ease: easing.easeInOut,
								duration: 300
							}).pipe(captionStyler.set),
							tween({
								from: { y: 60, opacity: 0 },
								to: { y: 0, opacity: 1 },
								ease: easing.easeInOut,
								duration: 300
							}).pipe(columnsStyler.set)
						).start();
					}
				}
			}, options);
			observer.observe(popupServicesInformationBlock[i]);
		}
	};
}

export {popupServicesInformation};