import Glide, { Controls, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'
import {EventObserver} from '../helper/eventObserver';

import {business} from './slides/business';
import {specs} from './slides/specs';
import {media} from './slides/media';

export const mainSliderObserver = new EventObserver();

let sliderCiaasInit;

const sliderCiaas = function() {
	const glide = document.querySelector('.popup-services-slider_ciaas');

	if (glide) {
		business();
		specs();
		media();

		sliderCiaasInit = new Glide('.popup-services-slider_ciaas', {
			type: 'slider',
			startAt: 0,
			perView: 1,
			gap: 40,
			autoplay: 5000
		});
	
		sliderCiaasInit.mount({
			Controls,
			Swipe,
			Autoplay
		});
		
		sliderCiaasInit.on('mount.after', slide => {
			mainSliderObserver.broadcast({
				active: sliderCiaasInit._c.Html.slides[0]
			})
		});
		sliderCiaasInit.on(['run.after'], () => {
			mainSliderObserver.broadcast({
				active: sliderCiaasInit._c.Html.slides[sliderCiaasInit.index]
			})
		});
	}
}

export {sliderCiaas, sliderCiaasInit};
