import SmoothScroll from 'smooth-scroll';

const scroll = function() {
	let scroll = new SmoothScroll('a[href*="#"]', {
		ignore: '[data-scroll-ignore]',
		header: '.header',
		speed: 300,
		offset: 0
	});
}

export {scroll};
