import Vivus from 'vivus';

import {mainSliderObserver} from '../slider'

const specs = () => {
	let slide = {};
	const vivusOptions = {
		type: 'delayed',
		duration: 200,
		delay: 100,
		start: 'manual'
	};
	const svgServicesCiaasSliderAirplane = new Vivus('popup-services-ciaas-slider-airplane', vivusOptions);

	mainSliderObserver.subscribe(slides => {
		slide = slides.active;
		if (slide.classList.contains('popup-services-slider__slide_specs')) {
			svgServicesCiaasSliderAirplane.el.style.visibility = 'visible';
			svgServicesCiaasSliderAirplane.reset().play();
		} else {
			svgServicesCiaasSliderAirplane.reset();
			svgServicesCiaasSliderAirplane.el.style.visibility = 'hidden';
		}
	})
}

export {specs};
